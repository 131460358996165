import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js'; // Import crypto-js

const Checkout = () => {
  const [err, setError] = useState(null);
  const navigate = useNavigate();
  const { fetchSecretKey } = useContext(AuthContext);
  const [globalSecretKey, setGlobalSecretKey] = useState("");
  const cypherKey = 'k&(!"k%Z`d-=hlCt';
  const [message, setMessage] = useState("");
  const [amount, setAmount] = useState("0.00"); // State to store the amount
  const [storedCart, setStoredCart] = useState(JSON.parse(localStorage.getItem("cart")) || []);


    // Calculate totalCart whenever storedCart changes
    useEffect(() => {
      const calculateTotal = () => {
        let totalSum = 0.00;
        storedCart.forEach(item => {
          totalSum += item.price * item.quantity;
        });
        setAmount(totalSum);
      };

      calculateTotal();
    }, [storedCart]);


  useEffect(() => {
    const getSecretKey = async () => {
      try {
        const response = await axios.post("/auth/fetchSecretKey");
        const secretKey = response.data; // Assuming the secretKey is sent directly in the response body
        if (secretKey) {
          const decryptedKey = decrypt(secretKey);
          setGlobalSecretKey(String(decryptedKey)); // Store the decrypted secret key in the state variable
        } else {
          console.error('SecretKey is undefined or null');
        }
      } catch (error) {
        console.error('Error fetching secretKey:', error);
        setError(error);
      }
    };

    getSecretKey();
  }, [fetchSecretKey]);

  // Encrypt function using crypto-js
  const encrypt = (text) => {
    return CryptoJS.AES.encrypt(text, cypherKey).toString();
  };

  // Decrypt function using crypto-js
  const decrypt = (text) => {
    const bytes = CryptoJS.AES.decrypt(text, cypherKey);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  };

  useEffect(() => {}, [globalSecretKey]);

  if (!globalSecretKey) {
    return <div>Loading...</div>; // Or any loading indicator while fetching the key
  }

  const initialOptions = {
    "client-id": globalSecretKey,
    "enable-funding": "paylater,venmo",
    "data-sdk-integration-source": "integrationbuilder_sc",
  };

  const validateAndFormatAmount = (amount) => {
    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount) || parsedAmount <= 0) {
      throw new Error("Invalid amount. Please enter a valid number greater than 0.");
    }
    return parsedAmount.toFixed(2);
  };

  const createOrder = async () => {
    try {
      const formattedAmount = validateAndFormatAmount(amount);
      const response = await fetch("/api/orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cart: [
            {
              id: "YOUR_PRODUCT_ID",
              quantity: "YOUR_PRODUCT_QUANTITY",
            },
          ],
          amount: formattedAmount, // Send the amount to the backend
        }),
      });

      const orderData = await response.json();

      if (orderData.id) {
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);

        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error(error);
      setMessage(`Could not initiate PayPal Checkout...${error}`);
    }
  };

  return (
    <div className="App" style={{width: '50%', marginLeft: '25%'}}>
      <h1 style={{textAlign: 'center'}}>checkout:</h1>
      <h3 style={{textAlign: 'center'}}>just a reminder, you're not actually buying anything here so</h3>
      <h4 style={{textAlign: 'center'}}>but again, thank you for your support</h4>
      <PayPalScriptProvider options={initialOptions} style={{width: '50%', marginLeft: '25%'}}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
          }}
          createOrder={createOrder}
          onApprove={async (data, actions) => {
            try {
              const response = await fetch(
                `/api/orders/${data.orderID}/capture`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );

              const orderData = await response.json();
              const errorDetail = orderData?.details?.[0];

              if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                return actions.restart();
              } else if (errorDetail) {
                throw new Error(
                  `${errorDetail.description} (${orderData.debug_id})`
                );
              } else {
                const transaction =
                  orderData.purchase_units[0].payments.captures[0];
                setMessage(
                  `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`
                );
                console.log(
                  "Capture result",
                  orderData,
                  JSON.stringify(orderData, null, 2)
                );
              }
            } catch (error) {
              console.error(error);
              setMessage(
                `Sorry, your transaction could not be processed...${error}`
              );
            }
          }}
        />
      </PayPalScriptProvider>
      <h1>{message}</h1>
    </div>
  );
};

export default Checkout;
