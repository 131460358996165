import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../context/authContext";

const Progress = () => {

  const [progressData, setProgressData] = useState([]);

  const [editId, setEditId] = useState(null); // ID of the progress entry being edited
  const [editValue, setEditValue] = useState(0); // Value of the progress being edited


  // The useLocation hook returns the current location object, which contains information about the current URL.
  const location = useLocation();
  // The useNavigate hook returns a navigate function that can be used to navigate to a new location.
  const navigate = useNavigate();

  // Extract the post ID from the current URL.
  const progressId = location.pathname.split("/")[2];

  // Get the current user from the AuthContext.
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
      const fetchProgress = async () => {
        try {
          const res = await axios.get("/progress");
          setProgressData(res.data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchProgress();
    }, []);

  const handleEditClick = (id, currentProgress) => {
    setEditId(id);
    setEditValue(currentProgress);
  };

  const handleEditChange = (e) => {
    setEditValue(e.target.value);
  };

  const handleUpdateClick = async () => {
    try {
      await axios.put(`/progress/${editId}`, { progress: editValue });
      setProgressData((prev) =>
        prev.map((entry) =>
          entry.id === editId ? { ...entry, progress: editValue } : entry
        )
      );
      setEditId(null);
      setEditValue(0);
    } catch (err) {
      console.log(err);
    }
    navigate("/profile");
  };


  // Render the blog post.
  return (
    <div className="meter-cont">
          {progressData.map((progress) => (
            <div key={progress.id} className="progress-cont">
              <h4 style={{marginLeft: '25%'}}>{progress.project}</h4>
              <div class="w3-border" style={{backgroundColor: 'white', width: '50%', marginLeft: '25%', border: '0px', borderRadius: '5px', marginBottom: '5px', marginBottom: '25px'}}>
                <div class="w3-grey" style={{height: '18px', width: `${progress.value}%`, backgroundColor: '#333'}}></div>
              </div>
              {currentUser && currentUser.ADMIN_BOOL > 0 && (
                <div style={{marginLeft: '25%'}}>
                  {editId === progress.id ? (
                    <div>
                      <input
                        type="number"
                        value={editValue}
                        onChange={handleEditChange}
                        min="0"
                        max="100"
                      />
                      <button onClick={handleUpdateClick}>Update</button>
                    </div>
                  ) : (
                    <button
                      onClick={() =>
                        handleEditClick(progress.id, progress.value)
                      }
                    >
                      Edit
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
  );
};

export default Progress;
