import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, BrowserRouter as Router, Route, Routes } from "react-router-dom";
import insta from "../images/insta.png";
import x from "../images/X.png";
import tiktok from "../images/tiktok.png";
import barcode from "../images/barcode.png";
import oneBackend from "../images/one-backend.png";
import twoFrontend from "../images/two-frontend.png";
import threeGraphicDesign from "../images/three-graphic-design.png";
import oneBackendBG from "../images/one-backend-bg.png";
import twoFrontendBG from "../images/two-frontend-bg.png";
import threeGraphicDesignBG from "../images/three-graphic-design-bg.png";


const Home = () => {
  const [usersData, setUsersData] = useState([]);
  const [posts, setPosts] = useState([]);
  const cat = useLocation().search;
  const containerRefs = useRef({});

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await axios.get("/users");
        setUsersData(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/posts${cat}`);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [cat]);

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };

  useEffect(() => {
    posts.forEach((post, index) => {
      if (post.description.includes("https://www.instagram.com")) {
        const url = post.description.match(/https:\/\/www\.instagram\.com\/p\/[A-Za-z0-9_-]+/);
        const embedCode = `
          <blockquote class="instagram-media" data-instgrm-permalink="${url}" data-instgrm-version="14">
            <a href="${url}"> </a>
          </blockquote>
        `;
        const container = containerRefs.current[index];
        if (container) {
          container.innerHTML = embedCode;

          const script = document.createElement('script');
          script.async = true;
          script.src = "https://www.instagram.com/embed.js";
          script.onload = () => {
            if (window.instgrm) {
              window.instgrm.Embeds.process();
            }
          };
          document.body.appendChild(script);

          return () => {
            document.body.removeChild(script);
          };
        }
      } else if (post.description.includes("https://twitter.com")) {
        const url = post.description.match(/https:\/\/twitter\.com\/[A-Za-z0-9_]+\/status\/[0-9]+/);
        const tweetEmbedCode = `
          <blockquote class="twitter-tweet" style="width:30%;">
            <a class="twitter-tweet" style="font-size:50rem;" href="${url}"> </a>
          </blockquote>
        `;
        const container = containerRefs.current[`twitter-${index}`];
        if (container) {
          container.innerHTML = tweetEmbedCode;

          const script = document.createElement('script');
          script.async = true;
          script.src = "https://platform.twitter.com/widgets.js";
          script.charset = "utf-8";
          script.onload = () => {
            if (window.instgrm) {
              window.instgrm.Embeds.process();
            }
          };
          document.body.appendChild(script);

          return () => {
            document.body.removeChild(script);
          };
        }
      } else if (post.description.includes("https://www.tiktok.com")) {
        const url = post.description.match(/https:\/\/www\.tiktok\.com\/p\/[A-Za-z0-9_-]+/);
        const endUrl = post.description.match("[^/]+$");
        const tiktokEmbedCode = `
          <blockquote class="tiktok-embed" cite="${url}" data-video-id="${endUrl}">
            <a href="${url}"> </a>
          </blockquote>
        `;
        const container = containerRefs.current[`tiktok-${index}`];
        if (container) {
          container.innerHTML = tiktokEmbedCode;

          const scriptThree = document.createElement('script');
          scriptThree.async = true;
          scriptThree.src = "https://www.tiktok.com/embed.js";
          document.body.appendChild(scriptThree);

          return () => {
            document.body.removeChild(scriptThree);
          };
        }
      }
    });
  }, [posts]);


   const getHomeHTML = () => {
        if (window.location.href == process.env.REACT_APP_FRONTEND_URL || window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/") || window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/#")) {
          return (
            <div>
                <div className="home-div" style={{ height: '100vh'}}>
                  <a href="https://www.instagram.com/cgfullstack/"><img src={insta} className="socialLogos" alt="insta" style={{ width: '30px', marginLeft: 'calc(50px - 2.5vw)', marginTop: '74.4vh', opacity: '.75', position: 'fixed'}}/></a>
                  <a href="https://x.com/CGFullstack"><img src={x} className="socialLogos" alt="x" style={{ width: '30px',marginTop: '74.4vh',  marginLeft: 'calc(50px - 2.5vw + 35px)', opacity: '.75', position: 'fixed'}}/></a>
                  <a href="https://www.tiktok.com/@cgfullstack"><img src={tiktok} className="socialLogos" alt="tiktok" style={{ width: '30px', marginTop: '74.4vh', marginLeft: 'calc(50px - 2.5vw + 35px + 35px)', opacity: '.75', position: 'fixed'}}/></a>
                  <div style={{marginTop: '10vh'}}><h1></h1></div>
                  <img src={barcode} style={{marginLeft: 'calc(-68.5px - 3vw)', height: '40px', width: '170px', marginTop: '60vh', transform: 'rotate(90deg)'}}/>
                </div>
                <div style={{marginLeft: '75vw', marginTop: '-45vh', marginBottom: '20vh', textAlign: 'right', fontFamily: 'Didot'}}><h1 style={{fontFamily: 'Didot', fontWeight: '50', fontSize: '3rem', marginLeft: '-47vw', width: '60vw', marginBottom: '40vh'}}>CG FULLSTACK WEB DEV</h1><h3 style={{fontFamily: 'Didot', fontWeight: '150', fontSize: '1.5rem', marginLeft: '-30vw', marginTop: '-40vh', width: '43vw'}}>websites by camden gear.</h3></div>
            </div>
          );
        }

   }

  const getUsernameOfPost = (users, id, uid) => {
        if(id == uid){
            return (
                <div key={id}>
                    <h4 className="underline">{users.username}</h4>
                </div>
            )
        }
  }

  const getHTML = (post, index) => {
    if (post.description.includes("https://www.instagram.com")) {
      return (
        <div className="instagram-div" style={{ transform: 'scale(1.35)', paddingBottom: '50px', marginLeft: '60px'}} key={post.id}>
          <center ref={(el) => (containerRefs.current[index] = el)}></center>
        </div>
      );
    } else if (post.description.includes("https://twitter.com")) {
      return (
        <div className="twitter-div" style={{ transform: 'scale(.8)', marginLeft: '60px', marginTop: '-50px', fontSize: '50rem;' }} key={post.id}>
          <center ref={(el) => (containerRefs.current[`twitter-${index}`] = el)}></center>
        </div>
      );
    } else if (post.description.includes("https://www.tiktok.com")) {
      return (
        <div className="tiktok-div" style={{ transform: 'scale(1.35)', marginLeft: 'calc(40vw - 130px)', zIndex: '0', width: '320px', borderRadius: '10px' }} key={post.id}>
          <center ref={(el) => (containerRefs.current[`tiktok-${index}`] = el)}></center>
        </div>
        );
    } else {
      return (
        <div className="post" key={post.id}>
          <div className="content">
            <Link className="link" to={`/post/${post.id}`}>
                <ul className="post-list">
                    <li>{usersData.map((users) => (
                        <div key={users.id}>
                            {getUsernameOfPost(users, users.id, post.uid) }
                        </div>
                     ))}
                    </li>
                    <li><h4>{new Date(post.date.split('T')[0]).toLocaleString('en-us').split(',')[0].toUpperCase()}</h4></li>
                 </ul>
              <h1>{post.title}</h1>
            </Link>
            <p>{getText(post.description)}</p>
            <Link className="link" to={`/post/${post.id}`}>
              <button>Read More</button>
            </Link>
          </div>
          <div className="post-img">
            <img src={`${post.img}`} alt="post cover" />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="home">
      {getHomeHTML()}
      <div className="posts">
        {window.location.href === process.env.REACT_APP_FRONTEND_URL + "/" || window.location.href === process.env.REACT_APP_FRONTEND_URL + "/#" ? (
            <div style={{ width: '105vw', marginLeft: '-15vw', paddingLeft: '15vw', backgroundColor: 'white', paddingTop: '10vh', paddingBottom: '10vh', zIndex: '1', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1)'}}>
                <ul className="homepage">
                    <li><img src={oneBackend} alt="one-backend-bg" /><div style={{ marginTop: 'calc(-6px - 80vh)', height: '80vh', borderRadius: '5px', transition: '1s linear', filter: 'brightness(85%)'}}><img src={oneBackendBG} alt="one-backend" /></div></li>
                    <li><img src={twoFrontendBG} alt="two-frontend-bg" /><div style={{ marginTop: 'calc(-6px - 80vh)', height: '80vh', borderRadius: '5px', transition: '1s linear'}}><img src={twoFrontend} alt="two-frontend" /></div></li>
                    <li><img src={threeGraphicDesign} alt="three-graphic-design-bg" /><div style={{ marginTop: 'calc(-6px - 80vh)', height: '80vh', transition: '1s linear', borderRadius: '5px', filter: 'brightness(85%)'}}><img src={threeGraphicDesignBG} alt="three-graphic-design" /></div></li>
                </ul>
            </div>
         ) : (
            Array.isArray(posts) ? (
              posts.map((post, index) => (
                <div key={post.id} style={{ zIndex: "20"}}>
                  {getHTML(post, index)}
                </div>
              ))
            ) : (
              <p>No posts available.</p>
            )
         )}
      </div>
    </div>
  );
};

export default Home;
