import React from "react";
import Logo from "../images/logo.png";

const Footer = () => {
  return (
    <footer>
      <span>
        <img src={Logo} alt="logo" />
        <br />
        <div>Made by Camden Gear</div>
        <div>work with me! email: gear.camden@gmail.com</div>
      </span>
    </footer>
  );
};

export default Footer;
