import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import cartLogo from "../images/cartLogo.png";
import Logo from "../images/logo.png";

const Navbar = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [cartVisible, setCartVisible] = useState(false);
  const [storedCart, setStoredCart] = useState(JSON.parse(localStorage.getItem("cart")) || []);
  const [totalCart, setTotalCart] = useState(0.00);
  const cartRef = useRef(null);

  const toggleCart = () => {
    setCartVisible(!cartVisible);
  };

  const logoutNavbar = () => {
    logout();
    navigate("/login");
  };

  function navbarHelper(){
    navigate("/#");
  };

  const navbarHelpTwo = () => {

  };

  window.onload = function exampleFunction() {

      let list = document.querySelectorAll(".list-new");

      list[0].className = "list-new nav-one";
      list[1].className = "list-new nav-two";
      list[2].className = "list-new nav-three";
      list[3].className = "list-new nav-four";

      var currURL = window.location.href;


      if((window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/#") || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/progress")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/store")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/admin")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/profile")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/checkout")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/checkout#")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/write")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/write#")))){
        console.log("no nav");
      } else if(localStorage.getItem("last-page") == "1"){
        console.log("last page one");
        list[0].className = "list-new nav-one active";
      } else if(localStorage.getItem("last-page") == "2"){
        console.log("last page two");
        list[1].className = "list-new nav-two active";
      } else if(localStorage.getItem("last-page") == "3"){
        console.log("last page three");
        list[2].className = "list-new nav-three active";
      } else if(localStorage.getItem("last-page") == "4"){
        console.log("last page four");
        list[3].className = "list-new nav-four active";
      }



      console.log("two");
      console.log(window.location.href);

       if((window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/#") || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/progress")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/store")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/admin")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/profile")))){
          list[3].className = "list-new nav-four";
          list[0].className = "list-new nav-one";
          list[1].className = "list-new nav-two";
          list[2].className = "list-new nav-three";
          console.log("List 1: " + list[0].className);
          console.log("List 2: " + list[1].className);
          console.log("List 3: " + list[2].className);
          console.log("List 4: " + list[3].className);
       } else if (currURL == `${process.env.REACT_APP_FRONTEND_URL}/?cat=${process.env.REACT_APP_PAGE_ONE_NAME}`){
         list[0].className = "list-new nav-one active";
         list[1].className = "list-new nav-two";
         list[2].className = "list-new nav-three";
         list[3].className = "list-new nav-four";
         localStorage.setItem("last-page", "1");
         console.log(`${process.env.REACT_APP_PAGE_ONE_NAME}`);
      } else if (currURL == `${process.env.REACT_APP_FRONTEND_URL}/?cat=${process.env.REACT_APP_PAGE_TWO_NAME}`){
        list[1].className = "list-new nav-two active";
        list[0].className = "list-new nav-one";
        list[2].className = "list-new nav-three";
        list[3].className = "list-new nav-four";
        localStorage.setItem("last-page", "2");
        console.log(`${process.env.REACT_APP_PAGE_TWO_NAME}`);
      } else if (currURL == `${process.env.REACT_APP_FRONTEND_URL}/?cat=${process.env.REACT_APP_PAGE_THREE_NAME}`){
        list[2].className = "list-new nav-three active";
        list[0].className = "list-new nav-one";
        list[1].className = "list-new nav-two";
        list[3].className = "list-new nav-four";
        localStorage.setItem("last-page", "3");
        console.log(`${process.env.REACT_APP_PAGE_THREE_NAME}`);
      } else if (currURL == `${process.env.REACT_APP_FRONTEND_URL}/?cat=${process.env.REACT_APP_PAGE_FOUR_NAME}`){
        list[3].className = "list-new nav-four active";
        list[0].className = "list-new nav-one";
        list[1].className = "list-new nav-two";
        list[2].className = "list-new nav-three";
        localStorage.setItem("last-page", "4");
        console.log(`${process.env.REACT_APP_PAGE_FOUR_NAME}`);
      } else {
        console.log("HOME")
        list[3].className = "list-new nav-four";
        list[0].className = "list-new nav-one";
        list[1].className = "list-new nav-two";
        list[2].className = "list-new nav-three";
        console.log("List 1: " + list[0].className);
        console.log("List 2: " + list[1].className);
        console.log("List 3: " + list[2].className);
        console.log("List 4: " + list[3].className);
      }




  }

  const clearCart = () => {
   localStorage.setItem("cart", JSON.stringify([]));
   navigate("/");
   location.reload();
  };

  const calculateTotal = (cart) => {
    let totalSum = 0.00;
    cart.forEach(item => {
      totalSum += item.price * item.quantity;
    });
    return totalSum;
  };

  // Update storedCart and totalCart state
  const updateCart = (updatedCart) => {
    setStoredCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    const total = calculateTotal(updatedCart);
    setTotalCart(total);
  };

  // Listen for storage events to update storedCart
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "cart") {
        const updatedCart = JSON.parse(event.newValue) || [];
        setStoredCart(updatedCart);
        const total = calculateTotal(updatedCart);
        setTotalCart(total);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // Update totalCart when cartVisible changes
  useEffect(() => {
    if (cartVisible) {
      const total = calculateTotal(storedCart);
      setTotalCart(total);
    }
  }, [cartVisible, storedCart]);


  //Navbar
  let list = document.querySelectorAll(".list-new");

  list.forEach((elements) => {
    elements.addEventListener("click", function (event) {
      let bg = document.querySelector("body");
      let color = event.target.getAttribute("data-color");
      console.log(event.target)
      bg.style.backgroundColor = color;
    });
  });

  //indicator
   const renderIndicator = () => {
         if((window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/#") || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/progress")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/store")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/admin")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/profile")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/progress#")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/store#")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/admin#")) || (window.location.href == (process.env.REACT_APP_FRONTEND_URL + "/profile#")))){
            console.log("HOME");
            return (
                <div className="navigation-new">
                      <div>
                        <ul>
                            <li className="list-new">
                              <a href={"/?cat=" + process.env.REACT_APP_PAGE_ONE_NAME}>
                                <span className="icon-new"><i className="far-new">my portfolio.</i></span>
                              </a>
                            </li>
                            <li className="list-new">
                              <a href={"/?cat=" + process.env.REACT_APP_PAGE_TWO_NAME}>
                                <span className="icon-new"><i className="far-new">my resume. </i></span>
                              </a>
                            </li>
                            <li className="list-new">
                              <a href={"/?cat=" + process.env.REACT_APP_PAGE_THREE_NAME}>
                                <span className="icon-new"><i className="far-new">my socials.</i></span>
                              </a>
                            </li>
                            <li className="list-new">
                              <a href={"/?cat=" + process.env.REACT_APP_PAGE_FOUR_NAME}>
                                <span className="icon-new"><i className="far-new">my side hustle.</i></span>
                              </a>
                            </li>
                          </ul>
                      </div>
                </div>
            );
         } else if ((localStorage.getItem("last-page") === null) || (localStorage.getItem("last-page") === "1")){
            return (
                <div className="navigation-new">
                      <div>
                <ul>
                    <li className="list-new nav-one" data-color="#dc143c">
                      <a href={"/?cat=" + process.env.REACT_APP_PAGE_ONE_NAME}>
                        <span className="icon-new"><i className="far-new">my portfolio.</i></span>
                      </a>
                    </li>
                    <li className="list-new nav-two" data-color="#3c40c6">
                      <a href={"/?cat=" + process.env.REACT_APP_PAGE_TWO_NAME}>
                        <span className="icon-new"><i className="far-new">my resume. </i></span>
                      </a>
                    </li>
                    <li className="list-new nav-three" data-color="#05c46b">
                      <a href={"/?cat=" + process.env.REACT_APP_PAGE_THREE_NAME}>
                        <span className="icon-new"><i className="far-new">my socials.</i></span>
                      </a>
                    </li>
                    <li className="list-new nav-four" data-color="#0fbcf9">
                      <a href={"/?cat=" + process.env.REACT_APP_PAGE_FOUR_NAME}>
                        <span className="icon-new"><i className="far-new">my side hustle.</i></span>
                      </a>
                    </li>
                    <div className="indicator-new"></div>
                  </ul>
                  </div>
                          </div>
            );
         } else if ((localStorage.getItem("last-page") === "2")){
            return (
                <div className="navigation-new">
                          <div>
                            <ul>
                                <li className="list-new nav-one" data-color="#dc143c">
                                  <a href={"/?cat=" + process.env.REACT_APP_PAGE_ONE_NAME}>
                                    <span className="icon-new"><i className="far-new">my portfolio.</i></span>
                                  </a>
                                </li>
                                <li className="list-new nav-two" data-color="#3c40c6">
                                  <a href={"/?cat=" + process.env.REACT_APP_PAGE_TWO_NAME}>
                                    <span className="icon-new"><i className="far-new">my resume. </i></span>
                                  </a>
                                </li>
                                <li className="list-new nav-three" data-color="#05c46b">
                                  <a href={"/?cat=" + process.env.REACT_APP_PAGE_THREE_NAME}>
                                    <span className="icon-new"><i className="far-new">my socials.</i></span>
                                  </a>
                                </li>
                                <li className="list-new nav-four" data-color="#0fbcf9">
                                  <a href={"/?cat=" + process.env.REACT_APP_PAGE_FOUR_NAME}>
                                    <span className="icon-new"><i className="far-new">my side hustle.</i></span>
                                  </a>
                                </li>
                                <div className="indicator-new-two"></div>
                              </ul>
                              </div>
                                      </div>
                        );
         } else if ((localStorage.getItem("last-page") === "3")){
            return (
                <div className="navigation-new">
                          <div>
                            <ul>
                                <li className="list-new nav-one" data-color="#dc143c">
                                  <a href={"/?cat=" + process.env.REACT_APP_PAGE_ONE_NAME}>
                                    <span className="icon-new"><i className="far-new">my portfolio.</i></span>
                                  </a>
                                </li>
                                <li className="list-new nav-two" data-color="#3c40c6">
                                  <a href={"/?cat=" + process.env.REACT_APP_PAGE_TWO_NAME}>
                                    <span className="icon-new"><i className="far-new">my resume. </i></span>
                                  </a>
                                </li>
                                <li className="list-new nav-three" data-color="#05c46b">
                                  <a href={"/?cat=" + process.env.REACT_APP_PAGE_THREE_NAME}>
                                    <span className="icon-new"><i className="far-new">my socials.</i></span>
                                  </a>
                                </li>
                                <li className="list-new nav-four" data-color="#0fbcf9">
                                  <a href={"/?cat=" + process.env.REACT_APP_PAGE_FOUR_NAME}>
                                    <span className="icon-new"><i className="far-new">my side hustle.</i></span>
                                  </a>
                                </li>
                                <div className="indicator-new-three"></div>
                              </ul>
                              </div>
                                      </div>
                        );
         } else if ((localStorage.getItem("last-page") === "4")){
            return (
                <div className="navigation-new">
                          <div>
                            <ul>
                                <li className="list-new nav-one" data-color="#dc143c">
                                  <a href={"/?cat=" + process.env.REACT_APP_PAGE_ONE_NAME}>
                                    <span className="icon-new"><i className="far-new">my portfolio.</i></span>
                                  </a>
                                </li>
                                <li className="list-new nav-two" data-color="#3c40c6">
                                  <a href={"/?cat=" + process.env.REACT_APP_PAGE_TWO_NAME}>
                                    <span className="icon-new"><i className="far-new">my resume. </i></span>
                                  </a>
                                </li>
                                <li className="list-new nav-three" data-color="#05c46b">
                                  <a href={"/?cat=" + process.env.REACT_APP_PAGE_THREE_NAME}>
                                    <span className="icon-new"><i className="far-new">my socials.</i></span>
                                  </a>
                                </li>
                                <li className="list-new nav-four" data-color="#0fbcf9">
                                  <a href={"/?cat=" + process.env.REACT_APP_PAGE_FOUR_NAME}>
                                    <span className="icon-new"><i className="far-new">my side hustle.</i></span>
                                  </a>
                                </li>
                                <div className="indicator-new-four"></div>
                              </ul>
                              </div>
                                      </div>
                        );
         }
   }


  return (
    <div className="navbar" onload="navbarHelper()">
        {renderIndicator()}

      <div className="container">
        <div className="logo">
          <a href="/#">
            <img src={Logo} className="main-logo" alt="logo" />
          </a>
        </div>
        <div className="links">
          <a href="#" onClick={toggleCart} className="cart-move">
            <img src={cartLogo} alt="cart logo" className="cartLogo"/>
            <i className=""></i><span className="badge">{storedCart.length}</span>
          </a>

          {/* SHOPPING CART */}
          {cartVisible && (
            <div className="container" ref={cartRef}>
              <div className="shopping-cart">
                <div className="shopping-cart-header">
                  <i className="fa"></i>
                  <span className="badge-two">{storedCart.length}</span>
                  <div className="shopping-cart-total">
                    <span className="lighter-text">Total:</span>
                    <span className="main-color-text">${totalCart.toFixed(2)}</span>
                  </div>
                </div>

                <ul className="shopping-cart-items">
                  {storedCart.map((item, index) => (
                    <li className="clearfix" key={index}>
                      <span className="item-name">{item.product}</span>
                      <span className="item-price">{item.price.toFixed(2)}</span>
                      <span className="item-quantity">quantity: {item.quantity}</span>
                    </li>
                  ))}
                </ul>
                <a href="/" style={{textDecoration: 'none'}}><button className="button" onClick={clearCart} style={{ width: "100%"}}>clear</button></a>
                <Link to="/checkout" className="button">
                  checkout
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>



              <div class="header-two">
                <input class="menu-btn" type="checkbox" id="menu-btn" />
                <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>
                <ul class="menu">
                  <li>
                    {currentUser?.username !== 0 && (
                      <Link className="link">
                        <h6>{currentUser?.username.toUpperCase()}</h6>
                      </Link>
                    )}
                  </li>
                  <li>
                        {currentUser && (
                            <span className="span-link"><h4>|</h4></span>
                          )}
                  </li>
                  <li>
                    {currentUser ? (
                      <span className="span-link" onClick={logoutNavbar}>
                        <h6 className="h6-three">LOGOUT</h6>
                      </span>
                    ) : (
                      <Link className="link" to="/login">
                        <h6 className="h6-two">LOGIN</h6>
                      </Link>
                    )}
                  </li>
                  <li><h4>|</h4></li>
                  <li>
                       {currentUser?.ADMIN_BOOL > 0 && (
                        <span className="write">
                          <Link className="link" to="/write">
                            <h6 className="h6">ADMIN</h6>
                          </Link>
                        </span>
                      )}
                  </li>
                  <li>
                          {currentUser && (
                              <span className="span-link"><h4>|</h4></span>
                            )}
                    </li>
                  <li>
                      <Link className="link" to="/progress">
                         <h6>PROGRESS</h6>
                      </Link>
                  </li>
                  <li><h4>|</h4></li>
                  <li>
                      <Link className="link" to="/store">
                        <h6>STORE</h6>
                      </Link>
                  </li>
                </ul>
              </div>



   </div>
  );
};

export default Navbar;
