import React, { useState, useContext } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { AuthContext } from "../context/authContext";

const Write = () => {
  const { currentUser } = useContext(AuthContext);

  // Get the location state using the `useLocation` hook
  // will be used to check if we are in writing or edit mode
  const state = useLocation().state;

  // Define the state variables
  const [title, setTitle] = useState(state?.title || "");
  const [value, setValue] = useState(state?.description || "");
  const [content, setContent] = useState(state?.content || "");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileBase64, setFileBase64] = useState("");
  const [cat, setCat] = useState(state?.cat || "");

  // Define the navigate function
  const navigate = useNavigate();

  // Define the handleClick function to handle the form submission
  const handleClick = async (e) => {
    e.preventDefault();

    try {
      // Send a PUT request to update a post if the location state is defined (writing),
      // otherwise send a POST request to create a new post
      state
        ? await axios.put(`/posts/${state.id}`, {
            title,
            description: value,
            content,
            cat,
            img: fileBase64,
          })
        : await axios.post(`/posts/`, {
            title,
            description: value,
            content,
            cat,
            img: fileBase64,
            date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
          });

      // Navigate to the homepage after the post is saved or updated
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  // Define the handleFileChange function to handle the file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : "");

    // Read the file and encode it as a Base64 string
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      setFileBase64(reader.result);
    };
    reader.onerror = (error) => {
      console.log("Error reading file: ", error);
    };
  };

  return (
    <div className="add" style={{ width: '50vw', marginLeft: '15vw' }}>
      <div className="content">
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <div className="editor-container">
          <ReactQuill
            className="editor"
            theme="snow"
            value={content}
            onChange={setContent}
          />
        </div>
      </div>

      <div className="menu">
        {currentUser?.ADMIN_BOOL > 0 && (
          <div className="item">
            <h1>Publish</h1>
            <span>
              <b>Status: </b> Draft
            </span>
            <span>
              <b>Visibility: </b> Public
            </span>
            <input
              style={{ display: 'none' }}
              type="file"
              id="file"
              onChange={handleFileChange}
            />
            <label htmlFor="file">Choose File</label>
            {fileName && <div>Selected file: {fileName}</div>}
            <div className="buttons">
              <button>Save as a draft</button>
              <button onClick={handleClick}>Publish</button>
            </div>
          </div>
        )}
        <div className="item">
          <h1>Category</h1>
          <div className="cat">
            <input
              type="radio"
              checked={cat === process.env.REACT_APP_PAGE_ONE_NAME}
              name="cat"
              value={process.env.REACT_APP_PAGE_ONE_NAME}
              id={process.env.REACT_APP_PAGE_ONE_NAME}
              onChange={(e) => setCat(e.target.value)}
            />
            <label htmlFor={process.env.REACT_APP_PAGE_ONE_NAME}>
              {process.env.REACT_APP_PAGE_ONE_NAME}
            </label>
          </div>
          <div className="cat">
            <input
              type="radio"
              checked={cat === process.env.REACT_APP_PAGE_TWO_NAME}
              name="cat"
              value={process.env.REACT_APP_PAGE_TWO_NAME}
              id={process.env.REACT_APP_PAGE_TWO_NAME}
              onChange={(e) => setCat(e.target.value)}
            />
            <label htmlFor={process.env.REACT_APP_PAGE_TWO_NAME}>
              {process.env.REACT_APP_PAGE_TWO_NAME}
            </label>
          </div>
          <div className="cat">
            <input
              type="radio"
              checked={cat === process.env.REACT_APP_PAGE_THREE_NAME}
              name="cat"
              value={process.env.REACT_APP_PAGE_THREE_NAME}
              id={process.env.REACT_APP_PAGE_THREE_NAME}
              onChange={(e) => setCat(e.target.value)}
            />
            <label htmlFor={process.env.REACT_APP_PAGE_THREE_NAME}>
              {process.env.REACT_APP_PAGE_THREE_NAME}
            </label>
          </div>
          <div className="cat">
              <input
                type="radio"
                checked={cat === process.env.REACT_APP_PAGE_FOUR_NAME}
                name="cat"
                value={process.env.REACT_APP_PAGE_FOUR_NAME}
                id={process.env.REACT_APP_PAGE_FOUR_NAME}
                onChange={(e) => setCat(e.target.value)}
              />
              <label htmlFor={process.env.REACT_APP_PAGE_FOUR_NAME}>
                {process.env.REACT_APP_PAGE_FOUR_NAME}
              </label>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Write;
