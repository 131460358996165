import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";

const Store = () => {
  const [storeData, setStoreData] = useState([]);
  const [editId, setEditId] = useState(null);
  const [editFields, setEditFields] = useState({
    product: '',
    description: '',
    price: 0,
    stock: 0,
    url: ''
  });
  const [cart, setCart] = useState([]);
  const [quantities, setQuantities] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchStore = async () => {
      try {
        const res = await axios.get("/store");
        setStoreData(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchStore();
  }, []);

  const handleEditClick = (id, currentFields) => {
    setEditId(id);
    setEditFields(currentFields);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFields({ ...editFields, [name]: value });
  };

  const handleUpdateClick = async () => {
    try {
      await axios.put(`/store/${editId}`, editFields);
      setStoreData((prev) =>
        prev.map((entry) =>
          entry.id === editId ? { ...entry, ...editFields } : entry
        )
      );
      setEditId(null);
      setEditFields({
        product: '',
        description: '',
        price: 0,
        stock: 0,
        url: ''
      });
    } catch (err) {
      console.log(err);
    }
    navigate("/profile");
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`/store/${id}`);
      setStoreData((prev) => prev.filter((entry) => entry.id !== id));
    } catch (err) {
      console.log(err);
    }
    navigate("/profile");
  };

  const handleQuantityChange = (e, id) => {
    setQuantities({ ...quantities, [id]: e.target.value });
  };

  const handleAddToCart = (product) => {
    const quantity = quantities[product.id] || 1;

    if (localStorage.getItem("cart") == null) {
        localStorage.setItem("cart", "[]");
    }

    const storedCart = JSON.parse(localStorage.getItem("cart"));
    const currentId = product.id;
    let found = false;

    for (let i = 0; i < storedCart.length; i++) {
       if (storedCart[i].id === currentId) {
         storedCart[i].quantity = parseInt(storedCart[i].quantity) + parseInt(quantity);
         found = true;
         break;
       }
    }

    if (!found){
        storedCart.push({
            id: product.id,
            product: product.product,
            price: product.price,
            quantity: parseInt(quantity),
        });
    }

    setCart(storedCart);
    localStorage.setItem("cart", JSON.stringify(storedCart));
    setQuantities({ ...quantities, [product.id]: 1 });
  };

  return (
    <div>
      <div style={{textAlign: 'center'}}>
          <h1 style={{color: '#333'}}>website builder coming soon</h1>
          <h4 style={{color: '#333'}}>for now, here's an example store.</h4>
          <h4 style={{fontSize: '.7rem', color: '#333'}}>these are just a bunch of not real products that you should in no means actually buy</h4>
          <h4 style={{fontSize: '.5rem', color: '#333'}}>unless of course you just want to help me out financially... i mean it's your money, but i appreciate it</h4>
      </div>
    <div className="meter-cont" style={{ gridTemplateColumns: 'auto', display: 'grid'}}>
      {storeData.map((store) => (
        <div key={store.id} className="store-cont" style={{gridColumnEnd: `${Math.ceil(store.id / 2) % 2}`, gridColumnStart: `${((store.id - 1) % 2)}`,  boxShadow: 'inset 0 -1px 1px rgba(255, 255, 255, 0.3)', borderRadius: '5px', padding: '0px', margin: '55px', backgroundColor: 'white'}}>
          <img src={`${store.img}`} alt="store image" style={{borderTopLeftRadius: '5px', borderTopRightRadius: '5px', width: '100%', marginLeft: '0%'}}/>
          <ul>
              <li><h4 style={{textAlign: 'center', fontSize: '1.5rem', marginBottom: '10px'}}>{store.product}</h4></li>
              <li>
                  <span className="fa fa-star" style={store.stock > 1 ? { color: '#333' } : { color: '#f3f3f3'}}></span>
                  <span className="fa fa-star" style={store.stock > 2 ? { color: '#333' } : {color: '#f3f3f3'}}></span>
                  <span className="fa fa-star" style={store.stock > 3 ? { color: '#333' } : {color: '#f3f3f3'}}></span>
                  <span className="fa fa-star" style={store.stock > 4 ? { color: '#333' } : {color: '#f3f3f3'}}></span>
                  <span className="fa fa-star" style={store.stock > 5 ? { color: '#333' } : {color: '#f3f3f3'}}></span>
              </li>
              <li><h4 style={{textAlign: 'center', fontSize: '1.4rem', marginBottom: '10px'}}>${store.price}</h4></li>
              <li><h4 style={{textAlign: 'center', fontSize: '1.3rem', marginBottom: '10px', fontStyle: 'italic'}}>{store.description}</h4></li>
          </ul>
          {currentUser && currentUser.ADMIN_BOOL > 0 ? (
            <div style={{marginLeft: 'auto'}}>
              {editId === store.id ? (
                <div>
                  <input
                    type="text"
                    name="product"
                    value={editFields.product}
                    onChange={handleEditChange}
                    placeholder="Product"
                  />
                  <input
                    type="text"
                    name="description"
                    value={editFields.description}
                    onChange={handleEditChange}
                    placeholder="Description"
                  />
                  <input
                    type="number"
                    name="price"
                    value={editFields.price}
                    onChange={handleEditChange}
                    placeholder="Price"
                    min="0"
                  />
                  <input
                    type="number"
                    name="stock"
                    value={editFields.stock}
                    onChange={handleEditChange}
                    placeholder="Stock"
                    min="0"
                  />
                  <input
                    type="text"
                    name="url"
                    value={editFields.url}
                    onChange={handleEditChange}
                    placeholder="Image URL"
                  />
                  <button onClick={handleUpdateClick}>Update</button>
                </div>
              ) : (
                <div style={{marginLeft: 'auto'}}>
                  <button onClick={() => handleEditClick(store.id, store)}>
                    Edit
                  </button>
                  <button onClick={() => handleDeleteClick(store.id)}>
                    Delete
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div>
              <input
                type="number"
                value={quantities[store.id] || 1}
                onChange={(e) => handleQuantityChange(e, store.id)}
                min="1"
                max="100"
                style={{marginLeft: '50%', marginRight: '50%', marginBottom: '10px', textAlign: 'center', fontFamily: 'Didot'}}
              />
              <a href="/"><button style={{width: '125px', marginLeft: 'calc(50% - 40px)', backgroundColor: 'white', marginBottom: '10px', fontFamily: 'Didot', border: '.5px solid #333'}} onClick={() => handleAddToCart(store)}>Add to Cart</button></a>
            </div>
          )}
        </div>
      ))}
    </div>
    </div>
  );
};

export default Store;
